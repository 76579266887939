import React, { useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql } from 'gatsby';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import Layout from '../components/Layout';
import Image from '../components/Image';
import CustomModal from '../components/CustomModal';
import ImageSlider from '../components/ImageSlider';
import SimilarProperties from '../components/SimilarProperties';
import PropertyFeatures from '../components/PropertyFeatures';
import PropertyHeroButtons from '../components/PropertyHeroButtons';
import AmenitiesList from '../components/AmenitiesList';
import BookingForm from '../components/BookingForm';
import GoogleMapComponent from '../components/GoogleMapComponent';
import { HTMLContent } from '../components/Content';
import testimonialQuotes from '../img/testimonials-quotes.svg';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const PropertyListingTemplate = ({
  helmet,
  pageData,
  propertyTitle,
  similarProperties,
  bookingData,
  pricing,
  anpIcon,
}) => {

  const [isBookingModalOpen, setBookingModalStatus] = useState(false);
  const toggleBookingModal = () => {
    if (isBookingModalOpen) {
      return setBookingModalStatus(false);
    }
    return setBookingModalStatus(true);
  };
  const handleBookingClick = () => toggleBookingModal();

  const [imagesModal, setImagesModalStatus] = useState({
    isOpen: false,
    index: 0,
  });

  const toggleImagesModal = (index) => {
    if (imagesModal.isOpen) {
      return setImagesModalStatus({ isOpen: false, index: 0 });
    }
    return setImagesModalStatus({ isOpen: true, index });
  };

  const renderDescription = (description) => {
    if (!!description) {
      return <HTMLContent content={description} />;
    }
    return <div>Enter description</div>;
  };
  const renderImageSlider = (images) => {
    if (!!images) {
      return (
        <ImageSlider
          sliderText={{
            photo: 'Photos',
            video: 'Videos',
            floorplan: 'Floorplan',
          }}
          sliderClass='property-slider'
          images={images}
          floorplan={pageData.floorplan}
          video={pageData.videoLink}
          imagesModal={imagesModal}
          toggleImagesModal={toggleImagesModal}
        />
      );
    }
    return <div>IMAGE SLIDER HERE</div>;
  };

  const renderANPText = () => {
    if (!pageData.allNiseko) {
      return null;
    }
    return (
      <div className='anp-note'>
        <Image
          imageInfo={{ image: anpIcon }}
          style={{
            width: '20px',
            display: 'inline',
            paddingTop: '2px',
            marginRight: '5px',
          }}
        />{' '}
        - ¥20,000 vouchers per night to spend at local partners
      </div>
    );
  };


  const renderMap = () => {
    if (!!pageData.coordinates) {
      return (
        <div className='container content map-section'>
          <h2 className='title'>Location</h2>
          <GoogleMapComponent coordinates={pageData.coordinates} />
        </div>
      );
    }
    return null;
  };

  return (
    <section className='section property-listing-page'>
      {helmet || ''}
      <div className='container content'>
        <div className='columns'>
          <div className='column is-4 vertically-centered is-flex property-title-section mobile--hide'>
            <div className='property-title-container'>
              <h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
                {propertyTitle}
              </h1>
              <hr className='hr-black' />
              <p>{pageData.short_description}</p>
              <div className='share-icons-container'>
                <FacebookShareButton
                  className='share-icons'
                  url={
                    typeof window !== 'undefined' ? window.location.href : ''
                  }>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  className='share-icons'
                  url={
                    typeof window !== 'undefined' ? window.location.href : ''
                  }>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <EmailShareButton
                  className='share-icons'
                  url={
                    typeof window !== 'undefined' ? window.location.href : ''
                  }>
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
            </div>
            <button
              className='btn btn-main booking-widget-button'
              onClick={() => handleBookingClick()}>
              Book Now
            </button>
          </div>
          <div className='column is-8 property-listing-main-image'>
            <Image
              imageInfo={{
                image: pageData.featuredImage,
                alt: `${pageData.title} featured image`,
              }}
              style={{ maxHeight: '70vh' }}
            />
            <PropertyHeroButtons
              sliderText={{
                photo: 'Photos',
                virtualTour: 'Virtual Tour',
                video: 'Videos',
                floorplan: 'Floorplan',
              }}
              sliderClass='property-slider'
              floorplan={pageData.floorplan}
              video={pageData.videoLink}
              virtualtour={pageData.virtualTour}
              imagesModal={imagesModal}
              toggleImagesModal={toggleImagesModal}
            />
          </div>
        </div>
      </div>

      <div className='container content'>
        <div className='columns description-section amenities-list-container'>
          <div className='column is-6'>
            <h2 className='mobile-property-title desktop--hide'>
              {propertyTitle}
            </h2>
            {renderANPText()}
            <div className='property-information'>
              {`${pageData.guests} | ${pageData.bedrooms} | ${pageData.bathrooms} | ${pageData.parking}`}
            </div>
            <div class="booking-button--mobile-container desktop--hide">
              <button
                  className='btn btn-main booking-widget-button'
                  onClick={() => handleBookingClick()}>
                  Book Now
              </button>
            </div>
            <div>
              {renderDescription(pageData.description_1)}
            </div>
            <br />
            <div>
              {renderDescription(pageData.description_2)}
            </div>
          </div>
          <div className='column is-6'>
            <AmenitiesList amenitiesTitle={ pageData.amenitiesTitle } linkObject={ pageData.amenitiesLinkObject } amenities={pageData.amenities} locale='en' />
          </div>
        </div>
      </div>


      <div className='container content tour-slider'>
        <h2 className='title'>Tour this home</h2>
        {renderImageSlider(pageData.photos)}
      </div>

      <PropertyFeatures features={pageData.features} locale='en' />

      {!!pageData.reviewObject &&
      !!pageData.reviewObject.review &&
      !!pageData.reviewObject.name ? (
        <div className='full-width testimonial-section'>
          <div className='container content testimonial-content'>
            <div className='has-text-centered'>
              <div className='review-text'>
                "{pageData.reviewObject.review}"
              </div>
              <h4 className='review-name'>{pageData.reviewObject.name}</h4>
            </div>
          </div>
          <div className='watermark quotation-mark'>
            <img alt='quotation' src={testimonialQuotes} />
          </div>
        </div>
      ) : null}

      {renderMap()}

      <div className='full-width similar-properties-section'>
        <div className='container content'>
          <h2 className='title similar-properties-title'>
            View similar properties
          </h2>
        </div>
        {!!similarProperties ? (
          <SimilarProperties
            title={propertyTitle}
            price={pricing}
            properties={similarProperties}
            locale=''
            propertyObject='property_data_en'
          />
        ) : null}
      </div>

      <div className='property-booking-widget'>
        <div className='container content'>
          <div className='has-text-right is-flex'>
            <div className='title-container'>
              <div className='title'>{propertyTitle}</div>
              {/* <span className='booking-widget-price desktop--hide'>{`From ¥ ${pricing} / night`}</span> */}
            </div>
            <div className='booking-items'>
              {/* <span className='booking-widget-price mobile--hide'>{`From ¥ ${pricing} / night`}</span> */}
              <button
                className='btn btn-main booking-widget-button'
                onClick={() => handleBookingClick()}>
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomModal onClose={toggleBookingModal} isOpen={isBookingModalOpen}>
        <BookingForm
          bookingUrl={pageData.booking_url}
          bookingData={bookingData}
          id={pageData.hotelId}
          pid={pageData.productId}
          multiProperty={pageData.multiProperty}
          onClose={toggleBookingModal}
          locale='en'
        />
      </CustomModal>
    </section>
  );
};

const PropertyListing = ({ data, location }) => {
  const { title, siteUrl } = useSiteMetadata();
  const { frontmatter } = data.markdownRemark;
  const pageData = R.merge(frontmatter, frontmatter.property_data_en);
  const propertyTitle = !!pageData.title ? pageData.title : frontmatter.title;
  
  const bookingData =
    !!location.state && !!location.state.bookingData
      ? location.state.bookingData
      : null;
  const pricing =
    !!location.state && !!location.state.price
      ? location.state.price
      : frontmatter.startingPrice;
  const metaTitle =
    !!pageData.seoObject && !!pageData.seoObject.metaTitle
      ? pageData.seoObject.metaTitle
      : `${frontmatter.title} - Luxury Vacation Rental in ${frontmatter.location} Japan`;
  const metaDescription =
    !!pageData.seoObject && !!pageData.seoObject.metaDescription
      ? pageData.seoObject.metaDescription
      : `${pageData.short_description}. Private ski vacation home rental in ${frontmatter.location} Japan.`;
  const helmet = (
    <MetaComponent
      title={metaTitle}
      canonical={`${siteUrl}${location.pathname}`}
      siteName={`${title}`}
      description={metaDescription}
      link={`${siteUrl}${location.pathname}`}
      image={`${pageData.featuredImage}`}
    />
  );
  return (
    <Layout>
      <PropertyListingTemplate
        location={location}
        pageData={pageData}
        propertyTitle={ propertyTitle }
        similarProperties={data.allMarkdownRemark.edges}
        bookingData={bookingData}
        pricing={pricing}
        anpIcon={data.anpIcon.frontmatter.icons.allNiseko}
        helmet={helmet}
      />
    </Layout>
  );
};

PropertyListing.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PropertyListing;

export const pageQuery = graphql`
  query propertyListingByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        hotelId
        productId
        multiProperty
        startingPrice
        featuredImage
        title
        allNiseko
        property_data_en {
          title
          short_description
          description_1
          description_2
          guests
          bedrooms
          bathrooms
          parking
          reviewObject {
            review
            name
          }
          features
          amenitiesTitle
          amenities
          amenitiesLinkObject {
            isExternal,
            link,
            title
          }
          seoObject {
            metaTitle
            metaDescription
          }
        }
        location
        videoLink
        virtualTour
        coordinates {
          latitude
          longitude
        }
        type
        floorplan {
          image
        }
        photos {
          label
          image
        }
        booking_url
        tags
      }
    }
    anpIcon: markdownRemark(
      frontmatter: { templateKey: { eq: "accommodation-page" } }
    ) {
      frontmatter {
        icons {
          allNiseko
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "property-listing" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            hotelId
            startingPrice
            type
            tags
            maxGuests
            featuredImage
            property_data_en {
              title
              short_description
              guests
              bedrooms
              bathrooms
            }
          }
        }
      }
    }
  }
`;